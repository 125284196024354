<template>
  <div id="login">
    <div class="background">
      <img src="@/assets/wave-footer.png" alt="background image" />
    </div>

    <Navbar class="white-text white-logo light-menu-icon" />

    <section>
      <h1 class="page-title">Login</h1>
      <div class="cards">
        <a
          class="card"
          v-for="(card, index) in cards"
          :key="index"
          :href="card.to"
          target="_blank"
        >
          <div class="type">{{ card.type }}</div>
          <div class="name">{{ card.name }}</div>
        </a>
      </div>
      <div class="signup">
        <h2>Don’t Have an Account?</h2>
        <router-link :to="{ name: 'sign-up' }"
          >Sign Up to Create One Now</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Login",
  components: {
    Navbar
  },
  metaInfo: function() {
    return {
      title: " - Login",
      meta: [
        {
          name: "description",
          content:
            "Publishers & Licensees, Record Labels, and Music Creators log in here to your accounts."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      cards: [
        {
          type: "Publishers & Licensees",
          name: "HFA Portal Login",
          to: "https://portal.harryfox.com"
        },
        {
          type: "Record Labels",
          name: "HFA eMechanical® Login",
          to: "https://portal.harryfox.com/auth/login"
        },
        {
          type: "Music Creators",
          name: "Songfile® Login",
          to: "http://www.songfile.com"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#login {
  > .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    z-index: -1;
    // padding-top: 80px;
    background-color: #01012e;

    img {
      width: 100%;
      transform: scale(-1) translateY(-60vw);
    }
  }

  section {
    margin-left: 5.8565vw;
    margin-right: 5.125vw;

    h1 {
      color: #fff;
      margin-top: 4.05vw;
      margin-bottom: 5.125vw;
      font-size: 1.25em;
      text-align: center;
    }

    .cards {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .card {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 22px 22px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 150px;
        margin-bottom: 20px;

        .type {
          font-size: 1.75em;
          letter-spacing: 0.2px;
          text-align: center;
          font-family: "Fjalla One", sans-serif;
          margin-bottom: 10px;
        }
        .name {
          font-size: 1.375em;
          text-align: center;
        }
      }
    }
    .signup {
      margin-top: 10.25vw;
      margin-bottom: 40px;
      text-align: center;

      h2 {
        font-size: 1.375em;
        font-family: "Fjalla One", sans-serif;
      }
      a {
        display: block;
        font-size: 1.1875em;
        text-decoration: underline;
      }
    }
  }

  @media (min-width: $desktop) {
    > .background {
      height: auto;

      img {
        transform: scale(-1) translateY(-7vw);
      }
    }

    section {
      h1 {
        margin-top: 4.05vw;
        margin-bottom: 5.125vw;
        font-size: 2em;
        text-align: left;
      }
      .cards {
        flex-direction: row;

        .card {
          width: 30%;
          padding: 7.32065vw 0;
          min-height: auto;
          margin-bottom: 0;
        }
      }
      .signup {
        margin-bottom: 0;
      }
    }
  }
}
</style>
